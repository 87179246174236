<template>
  <div>
    <ServicesTopBar />
    <Banner title="FAQs" />
    <template>
      <section class="page_detail_section">
        <div class="container">
          <b-card class="details-page-box">
            <app-collapse accordion>
              <app-collapse-item
                v-for="(item, index) in contents"
                :key="index"
                :title="item.question"
              >
                {{ item.answer }}
              </app-collapse-item>
            </app-collapse>
          </b-card>
        </div>
      </section>
      <SiteFooter />
    </template></div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Banner from '../components/page/Banner.vue'
import PageDetails from '../components/page/PageDetails.vue'
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: {
    ServicesTopBar,
    SiteFooter,
    Banner,
    PageDetails,
    BCard,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {

    }
  },
  computed: {
    contents() {
      return this.$store.state.page_content?.page_contents?.content
    },
  },
  mounted() {
    this.getFaqContent()
  },
  methods: {
    getImage(image) {
      return `background: url(${image})`
    },
    getFaqContent() {
      return this.$store.dispatch('page_content/getAllContent', 'faqs')
    },
  },
}
</script>

<style scoped>

</style>
